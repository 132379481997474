import React from "react";
import { NavLink } from "react-router-dom";
import Switcher from "../Elements/Switcher";

// var bgimage = require("./../../images/background/bg-site.png");

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.currentstate = { logo: require("./../../images/logo.png") };
  }

  updateFooterLogo = (updatedlogo) => {
    this.currentstate.logo = updatedlogo;
  };

  render() {
    return (
      <>
        <footer className="site-footer footer-large	footer-wide">
          {/* FOOTER BLOCKES START */}
          <div className="footer-top overlay-wraper">
            <div className="overlay-main" />
            <div className="container">
              <div className="row">
                {/* ABOUT COMPANY */}
                <div className="col-lg-4 col-md-6 col-sm-6">
                  <div className="widget widget_about">
                    {/*<h4 class="widget-title">About Company</h4>*/}
                    <div className="logo-footer clearfix p-b15">
                      <NavLink to={"./"}>
                        <img src={this.currentstate.logo} alt="" />
                      </NavLink>
                    </div>
                    <p className="max-w400">
                      Today we can tell you, thanks to your passion, hard work
                      creativity, and expertise, you delivered us the most
                      beautiful house great looks.
                    </p>
                  </div>
                </div>
                {/* RESENT POST */}
                <div className="col-lg-4 col-md-6 col-sm-6">
                  <div className="widget widget_address_outer">
                    <h4 className="widget-title">Contact Us</h4>
                    <ul className="widget_address">
                      <li>
                        Naccache, Main Street, Section 6, Building 1682, Block B
                      </li>
                      <li>
                        <a
                          href="mailto:support@ytc-group.com"
                          style={{
                            textDecoration: "none",
                            color: "black",
                            cursor: "pointer",
                          }}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Support@ytc-group.com
                        </a>
                      </li>
                      <li>+961-03-139316</li>
                    </ul>
                  </div>
                </div>
                {/* USEFUL LINKS */}
                <div className="col-lg-4 col-md-6 col-sm-6 footer-col-3">
                  <div className="widget widget_services inline-links">
                    <h4 className="widget-title">Useful links</h4>
                    <ul>
                      <li>
                        <NavLink to={"/about"}>About</NavLink>
                      </li>
                      <li>
                        <NavLink to={"/projects"}>Projects</NavLink>
                      </li>
                      <li>
                        <NavLink to={"/services"}>Services</NavLink>
                      </li>
                      <li>
                        <NavLink to={"/contactus"}>Contact Us</NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* FOOTER COPYRIGHT */}
          <div className="footer-bottom overlay-wraper">
            <div className="overlay-main" />
            <div className="container">
              <div className="row">
                <div className="mt-footer-bot-center">
                  <span className="copyrights-text">
                    ©{new Date().getFullYear()} YTC Group.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <Switcher updateFooterLogo={this.updateFooterLogo.bind(this)} />
      </>
    );
  }
}

export default Footer;
