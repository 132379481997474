import React from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import About3 from "../Elements/About3";
import Specialization3 from "../Elements/Specialization3";
import OurProject4 from "../Elements/OurProject4";
import OurServices4 from "../Elements/OurServices4";

class Home4 extends React.Component {
  render() {
    return (
      <>
        <Header />
        <div className="page-content">
          <About3 />
          <Specialization3 />
          {/* <OurValue2 /> */}
          <OurProject4 />
          <OurServices4 />
        </div>
        <Footer />
      </>
    );
  }
}

export default Home4;
