import React from 'react';
// import { NavLink } from 'react-router-dom';


const projects = [
    {
        thumb: require('./../../images/projects/real/Aqua.png'),
        image: require('./../../images/projects/real/Aqua.png'),
        title: 'Aqua Stage 2A;2B;2C - Penrith, NSW, Australia',
        description: `....`,
        filter: 'cat-1'
    },
    {
        thumb: require('./../../images/projects/real/Belmore1.png'),
        image: require('./../../images/projects/real/Belmore2.png'),
        title: 'Belmore - Wollongong, NSW, Australia',
        description: `...`,
        filter: 'cat-2'
    },
    {
        thumb: require('./../../images/projects/real/Court.png'),
        image: require('./../../images/projects/real/Court.png'),
        title: 'Court Road, Fairfield - NSW, Australia',
        description: `....`,
        filter: 'cat-3'
    },
    {
        thumb: require('./../../images/projects/real/Fairmont.jpg'),
        image: require('./../../images/projects/real/Fairmont.jpg'),
        title: 'Fairmont Hotel - Khobar, Saudi Arabia',
        description: `...`,
        filter: 'cat-4'
    },
    {
        thumb: require('./../../images/projects/real/Key.png'),
        image: require('./../../images/projects/real/Key.png'),
        title: 'Ker Parade - Sydney, NSW, Australia',
        description: `...`,
        filter: 'cat-5'
    },
    {
        thumb: require('./../../images/projects/real/Parcel.png'),
        image: require('./../../images/projects/real/Parcel.png'),
        title: 'Parcel 5.04 King Abdullah Financial district - Riyadh, Saudi Arabia',
        description: `...`,
        filter: 'cat-4'
    },
    {
        thumb: require('./../../images/projects/real/Pearl1.png'),
        image: require('./../../images/projects/real/Pearl2.png'),
        title: 'Pearl Villa - Doha, Qatar',
        description: `...`,
        filter: 'cat-3'
    },
    {
        thumb: require('./../../images/projects/real/Raphael.png'),
        image: require('./../../images/projects/real/Raphael.png'),
        title: 'Raphael St. - Lidcombe, NSW, Australia',
        description: `...`,
        filter: 'cat-2'
    },
    {
        thumb: require('./../../images/projects/real/Red.png'),
        image: require('./../../images/projects/real/Red.png'),
        title: 'Red Sea Dune Hotel # 14 - Jeddah, Saudi Arabia',
        description: `...`,
        filter: 'cat-1'
    }
]

class OurProject4 extends React.Component {
    componentDidMount() {
        function loadScript(src) {

            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            })
        };

        loadScript('./assets/js/masonary.js');

    };
    render() {
        return (
            <>
                <div className="section-full p-t80 p-b50 bg-gray mobile-page-padding">
                        <div className="container">
                            {/* TITLE START */}
                            <div className="section-head">
                                <div className="mt-separator-outer separator-center">
                                    <div className="mt-separator">
                                        <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Our</span> Projects</h2>
                                    </div>
                                </div>
                            </div>
                            {/* TITLE END */}

                            {/* GALLERY CONTENT START */}
                            <div className="portfolio-wrap mfp-gallery work-grid row clearfix">
                                {projects.map((item, index) => (
                                    <div key={index} className={`${item.filter} masonry-item col-md-4 col-sm-6 m-b30`}>
                                        <div className="image-effect-two hover-shadow">
                                            <img src={item.image} alt="" />
                                            <div className="figcaption">
                                                <h4 className="mt-tilte">{item.title}</h4>
                                                <p>{item.description}</p>
                                                {/* <NavLink to="/project-detail" className="read-more site-button btn-effect">Read More</NavLink>
                                                <a className="mfp-link" href={item.image2}>
                                                    <i className="fa fa-arrows-alt" />
                                                </a> */}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {/* GALLERY CONTENT END */}
                            {/* <div className="m-t50 text-center">
                                <NavLink to={"/projects"} className="site-button btn-effect">Load More</NavLink>
                            </div> */}
                        </div>
                    </div>    
            </>
        );
    }
};

export default OurProject4;