import React from "react";
import Header from "./../Common/Header";
import Footer from "./../Common/Footer";
import Banner from "./../Elements/Banner";

var bnrimg = require("./../../images/banner/contactBanner.jpg");

class ContactUs extends React.Component {
  render() {
    return (
      <>
        <Header />
        <div className="page-content">
          <Banner
            title="Putting a plan to action, to assure your satisfaction!"
            pagename="Contact Us"
            bgimage={bnrimg}
          />

          {/* SECTION CONTENTG START */}
          <div className="section-full p-tb80 inner-page-padding">
            {/* LOCATION BLOCK*/}
            <div className="container">
              {/* GOOGLE MAP & CONTACT FORM */}
              <div className="section-content">
                {/* CONTACT FORM*/}
                <div className="row">
                  <div className="col-md-12 col-sm-12">
                    <div className="contact-info m-b30">
                      {/* TITLE START */}
                      <div className="section-head">
                        <div className="mt-separator-outer separator-left">
                          <div className="mt-separator">
                            <h2 className="text-uppercase sep-line-one ">
                              <span className="font-weight-300 text-primary">
                                Contact
                              </span>{" "}
                              Info
                            </h2>
                          </div>
                        </div>
                      </div>
                      {/* TITLE END */}
                      <div className="bg-dark p-a20 text-white">
                        <div className="mt-icon-box-wraper left p-b40">
                          <div className="icon-xs">
                            <i className="fa fa-phone" />
                          </div>
                          <div className="icon-content">
                            <h5 className="m-t0 font-weight-500">
                              Phone number
                            </h5>
                            <p>+961-03-139316</p>
                          </div>
                        </div>
                        <div className="mt-icon-box-wraper left p-b40">
                          <div className="icon-xs">
                            <i className="fa fa-envelope" />
                          </div>
                          <div className="icon-content">
                            <h5 className="m-t0 font-weight-500">
                              Email address
                            </h5>
                            <a
                              href="mailto:support@ytc-group.com"
                              style={{
                                textDecoration: "none",
                                color: 'white',
                                cursor: "pointer",
                              }}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Support@ytc-group.com
                            </a>
                          </div>
                        </div>
                        <div className="mt-icon-box-wraper left">
                          <div className="icon-xs">
                            <i className="fa fa-map-marker" />
                          </div>
                          <div className="icon-content">
                            <h5 className="m-t0 font-weight-500">
                              Address info
                            </h5>
                            <p>
                              Naccache, Main Street, Section 6, Building 1682,
                              Block B
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </>
    );
  }
}

export default ContactUs;
