import React from "react";
// import { NavLink } from "react-router-dom";

var bnr1 = require("./../../images/background/bg-6.png");

class Specialization3 extends React.Component {
  render() {
    return (
      <>
        <div
          className="section-full p-t80 p-b50 bg-dark mobile-page-padding"
          style={{ backgroundImage: "url(" + bnr1 + ")" }}
        >
          <div className="container">
            {/* IMAGE CAROUSEL START */}
            <div className="section-content">
              <div className="section-head m-b50 text-white text-center">
                <h3 className="m-t0">
                  Pioneering Excellence in Civil Engineering Consultancy
                </h3>
                <p style={{ textAlign: "justify" }}>
                  Our mission is to provide innovative, sustainable, and
                  cost-effective solutions to meet our clients' needs while
                  upholding the highest standards of quality and safety. Our
                  vision is to become a leading civil engineering consulting
                  firm that is recognized for our exceptional work and
                  commitment to our clients.
                </p>
              </div>

              <div className="row">
                <div className="col-md-4 col-sm-6 m-b30">
                  <div className="image-effect-one hover-shadow">
                    <img src={require("../../images/ytc/ytc-client.jpg")} alt="" />
                    <div className="figcaption">
                      <h4>Client-focused</h4>
                      <p>
                        We prioritize our clients and strive to meet and exceed
                        their expectations in every project.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 col-sm-6 m-b30">
                  <div className="image-effect-one hover-shadow">
                    <img src={require("../../images/ytc/ytc-quality.png")} alt="" />
                    <div className="figcaption">
                      <h4>Quality</h4>
                      <p>
                        We are committed to delivering high-quality work that
                        meets industry standards and best practices.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 col-sm-12 m-b30">
                  <div className="image-effect-one hover-shadow">
                    <img src={require("../../images/ytc/ytc-efficiency.jpg")} alt="" />
                    <div className="figcaption bg-dark">
                      <h4>Efficiency</h4>
                      <p>
                        We work diligently to ensure that our projects are
                        completed on time and within budget.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-sm-12 m-b30">
                  <div className="image-effect-one hover-shadow">
                    <img src={require("../../images/ytc/ytc-pro.jpg")} alt="" />
                    <div className="figcaption bg-dark">
                      <h4>Professionalism</h4>
                      <p>
                        We conduct our work with the highest level of
                        professionalism, integrity, and respect for all
                        stakeholders.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-sm-12 m-b30">
                  <div className="image-effect-one hover-shadow">
                    <img src={require("../../images/ytc/ytc-power.png")} alt="" />
                    <div className="figcaption bg-dark">
                      <h4>Sustainability</h4>
                      <p>
                        We promote sustainable engineering practices that
                        minimize the impact on the environment and society.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Specialization3;
