import React from "react";
import Header from "./../Common/Header";
import Footer from "./../Common/Footer";
import Banner from "./../Elements/Banner";
import OurServices from "../Elements/OurServices4";

var bnrimg = require("./../../images/banner/servicesBanner.jpg");

class Services extends React.Component {
  render() {
    return (
      <>
        <Header />
        <div className="page-content">
          <Banner
            title="Diversified services. Unvarying quality."
            pagename="Services"
            bgimage={bnrimg}
          />
          <OurServices />
          <Footer />
        </div>
      </>
    );
  }
}

export default Services;
