import React from "react";

const services1 = [
  {
    title: "Construction of Residential and Commercial Buildings",
    flaticon: "flaticon-city",
    description:
      "The company has delivered several residential and commercial buildings for both public and private sectors, including offices, commercial centers, shopping malls, hospitals, factories, warehouses, and villas, town houses, duplexes, multi-building residential and commercial complexes.",
  },
  {
    title: "Finishing, Architectural and Interior Design Works",
    flaticon: "flaticon-sketch",
    description:
      "Designing properties, including homes and other commercial spaces, and then executing the designs to create a particular environment that is desired by the client/clients. Our duties also include sourcing furniture and materials, conducting presentations, and sketching concepts using design software.",
  },
  {
    title: "Construction of Roads and Infrastructure Networks",
    flaticon: "flaticon-paint",
    description:
      "Providing high quality infrastructure work, including sewage, water supply, storm water, electrical infrastructure, drainage, road work, sidewalks, landscaping, asphalt and lighting.",
  },
  {
    title: "Decorative Concrete",
    flaticon: "flaticon-crane",
    description:
      "Providing plain, colored, and stamped concrete, exposed aggregate, glass fiber reinforced concrete (GFRC), in addition to trowel machine finishing.",
  },
];
const services2 = [
  {
    title: "Renovation and Rehabilitation Works",
    flaticon: "flaticon-chart",
    description:
      "Restoration is ‘the process of assisting the recovery of an ecosystem that has been degraded, damaged, or destroyed’, whereas rehabilitation focuses on reparation of ecosystem processes, productivity and services.",
  },

  {
    title: "Procurement of goods and services",
    flaticon: "flaticon-crane-1",
    description:
      "Preparing a clear procurement plan, item categorization, reaching out biggest pool of suppliers after finishing the needed tenders and FWA. Following up on deliverables in terms of quality and quantities.",
  },
  {
    title: "Structural Drawing",
    flaticon: "flaticon-decorating",
    description:
      "Reinforced concrete, post tension concrete, retaining and shear walls, shop drawings, BOQ, value engineering, traffic engineering and planning. Software: Robot, Etabs, Autocade, Safe, S-Concrete, Inducta, RamV8, Revit (to be inserted as icons)",
  },
];

var img1 = require("./../../images/background/bg-5.png");
var img2 = require("./../../images/background/line.png");

class OurServices4 extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript("./assets/js/masonary.js");
  }
  render() {
    return (
      <>
        <div
          className="section-full p-t80 p-b50 bg-dark mobile-page-padding"
          style={{ backgroundImage: "url(" + img1 + ")" }}
        >
          <div className="container">
            {/* TITLE START */}
            <div className="section-head">
              <div className="mt-separator-outer separator-center">
                <div className="mt-separator text-white">
                  <h2 className="text-uppercase sep-line-one ">
                    <span className="font-weight-300 text-primary">All</span>{" "}
                    Services
                  </h2>
                </div>
              </div>
            </div>
            {/* TITLE END */}
            {/* IMAGE CAROUSEL START */}
            <div className="section-content">
              <div className="row">
                {services2.map((item, index) => (
                  <div key={index} className="col-md-4 col-sm-6">
                    <div className="mt-icon-box-wraper p-a30 center m-b30 bg-white">
                      <div
                        className="mt-icon-box-sm inline-icon text-primary  m-b20 radius bg-primary  scale-in-center bg-moving"
                        style={{ backgroundImage: "url(" + img2 + ")" }}
                      >
                        <span className="icon-cell text-secondry">
                          <i className={item.flaticon} />
                        </span>
                      </div>
                      <div className="icon-content">
                        <h4 className="mt-tilte text-uppercase font-weight-600 m-b20">
                          {item.title}
                        </h4>
                        <p style={{textAlign: 'justify'}}>{item.description}</p>
                        {/* <NavLink to={"/services"} className="site-button-link" data-hover="Read More">Read More <i className="fa fa-angle-right arrow-animation" /></NavLink> */}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="row">
                {services1.map((item, index) => (
                  <div key={index} className="col-md-4 col-sm-6">
                    <div className="mt-icon-box-wraper p-a30 center m-b30 bg-white">
                      <div
                        className="mt-icon-box-sm inline-icon text-primary  m-b20 radius bg-primary  scale-in-center bg-moving"
                        style={{ backgroundImage: "url(" + img2 + ")" }}
                      >
                        <span className="icon-cell text-secondry">
                          <i className={item.flaticon} />
                        </span>
                      </div>
                      <div className="icon-content">
                        <h4 className="mt-tilte text-uppercase font-weight-600 m-b20">
                          {item.title}
                        </h4>
                        <p style={{textAlign: 'justify'}}>{item.description}</p>
                        {/* <NavLink to={"/services"} className="site-button-link" data-hover="Read More">Read More <i className="fa fa-angle-right arrow-animation" /></NavLink> */}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default OurServices4;
