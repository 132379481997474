import React from "react";
import { NavLink } from "react-router-dom";

var bnr1 = require("./../../images/background/line.png");

class About4 extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript("./assets/js/masonary.js");
  }

  render() {
    return (
      <>
        <div className="section-full p-b80 bg-white inner-page-padding">
          <div className="container">
            <div className="section-content ">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="m-about-containt text-black p-t80">
                    <div
                      className="m-about-years bg-moving"
                      style={{ backgroundImage: "url(" + bnr1 + ")" }}
                    >
                      <span className="text-primary large-title">YTC</span>
                      <span className="large-title-info">
                        General contracting
                      </span>
                    </div>
                    {/* <h3 className="font-weight-600">
                      Improving quality of life with an integrated unified
                      approach.
                    </h3> */}
                    <h5 style={{textAlign: 'justify'}}>
                      Growing companies keep their past in mind and aspire to
                      achieve unprecedented milestones in their future. We at
                      YTC, operate based on the same ethical standards and moral
                      guidelines that prompted our founding. Our corporation is
                      based on four main pillars: integrity, professional
                      competence, dedication, and quality. The timely completion
                      of all projects without sacrificing quality is our aim,
                      and maintaining client satisfaction is our guiding
                      concept. We have accomplished construction projects
                      throughout all major areas of the industry, including
                      design, contracting, infrastructure, consultancy services,
                      in addition to residential housing projects in a variety
                      of cities. Our success has been heavily dependent on our
                      competent professional team and our devotion to client
                      satisfaction. Long-term objectives encompass broadening
                      our activities to further Lebanese regions in order to
                      enhance our business opportunities. We believe that by
                      combining dedicated employees with advanced project
                      management techniques, we can achieve long-term growth and
                      success.
                    </h5>
                    <div className="author-info p-t20">
                      <NavLink
                        to="/contactus"
                        className="site-button btn-effect m-b15"
                      >
                        <span>Get in touch</span>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default About4;
